import style_constants from './style_constants.js';
import { createMuiTheme } from '@material-ui/core/styles';
const conventio_theme = createMuiTheme(
    {
      palette: {
        primary: {
          main: style_constants.primary_color
        },
        secondary: {
          main: style_constants.accent_color
        }
      },
      typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
          'Arial'
        ].join(','),
        useNextVariants: true,
      },
      overrides:{
        MuiFormControl: {
          root: {
              borderBottom: 0,
          },
        },
        MuiInputBase:{
          root:{
            borderBottom: 0,
          },
          input:{
            color: style_constants.body1_color
          }
        },
        MuiFilledInput:{
          root:{
            backgroundColor: style_constants.input_background_color
          },
          underline:{
            borderBottom: '1px solid '+style_constants.primary_color,
            '&:before': {
              borderBottom: 0,
            },
            "&:after": {
              borderBottom: 0,
            }
          },
          error:{
            borderBottom: '1px solid '+style_constants.error_color,
            '&:before': {
              borderBottom: 0,
            },
            "&:after": {
              borderBottom: 0,
            }
          }
        },
        MuiTypography:{
          h2:{
            fontWeight: '900',
            fontSize: '3rem'
          },
          h3:{
            color: style_constants.accent_color,
            fontSize: '1.6rem',
            fontWeight: '900',
            textTransform: 'uppercase'
          },
          h4:{
            color: style_constants.accent_color,
            fontSize: '1.5rem'
          },
          h5:{
            color: style_constants.accent_color,
            fontSize: '1.2rem',
            fontWeight: '700',
          },
          body1: {
            color: style_constants.body1_color
          },
          subtitle1: {
            color: style_constants.primary_color,
            fontSize: '2rem',
            textTransform: 'uppercase'
          },
          subtitle2:{
            color: style_constants.accent_color,
            fontWeight: '700',
            fontSize: '1.2rem',
            textTransform: 'none'
          },
          h6:{
            color: style_constants.subtitle1_color,
            textTransform: 'uppercase',
            fontWeight: 'bold'
          },
          title:{
            color: style_constants.subtitle1_color,
            textTransform: 'uppercase',
            fontWeight: 'bold'
          }
        },
        MuiTableCell:{
          body: {
            color: style_constants.body1_color,
            fontSize: '1rem',
            borderBottom: '1px solid '+style_constants.primary_color
          },
        }
      }
    }
  );

export default conventio_theme;