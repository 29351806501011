const style_constants = {
    primary_color: '#4d8aee',
    accent_color: '#000000',
    secondary_color: '#4b3431',
    row_bg_color: '#C3EDF6',
    disabled_color: '#B6BABF',
    error_color: '#f44336',
    background_color: '#D2D2D2',
    header_bg_color: '#4d8aee',
    input_background_color: 'rgb(220,228,229)',
    light_border_color: 'rgb(229,229,229)',
    font_medium: 'Arial',
    font_bold: 'Arial',
    subtitle1_color: '#4b3431',
    body1_color: '#080808',
    button_gray: 'rgb(79,79,79)'
    
  };

export default style_constants;