import React from 'react';
import { Route } from "react-router-dom";
import HomeScreen from '../signup/HomeScreen';
import routes from './routes.js';

export default class ConventioRouter extends React.Component{

    render(){
        return (
            <div>
                <Route exact path={routes.home} component={HomeScreen}/>
            </div>
        );
    }
}
