import style_constants from './style_constants.js';

const drawerWidth = 190;

const styles = theme => ({
    
    root: {
      display: 'flex',
      backgroundColor: style_constants.background_color
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
      backgroundColor: style_constants.accent_color,
    },
    button:{
      color: 'white',
      fontWeight: '700'
    },
    menuButton: {
      marginRight: 20,
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '14.5vh',
      ...theme.mixins.toolbar,
    },
    toolbar_img:{
      textAlign: 'center',
      paddintTop: '10px'
    },
    drawerPaper: {
      width: drawerWidth,
      backgroundColor: style_constants.primary_color,
    },
    menu_item_text:{
      fontSize: 14,
      color: 'white',
      textDecoration: 'none'
    },
    fab: {
      position: 'fixed',
      bottom: theme.spacing.unit * 2,
      right: theme.spacing.unit * 2,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing.unit * 2,
      width: '100vw'
    },
    formControl:{
      width: '90%'
    },
    paper: {
      padding: theme.spacing.unit * 2,
      margin: 'auto',
    },
    chip: {
      margin: theme.spacing.unit,
    },
    close: {
      padding: theme.spacing.unit / 2,
    },
    buttonProgress: {
      color: 'white',
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    progress_button_wrapper:{
      margin: theme.spacing.unit,
      position: 'relative',
    }
    
  
  });

  export default styles;