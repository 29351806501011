export const config={
    API_URL:"https://api.conventio.co",
    //API_URL:"https://qaapi.conventio.co",
    //API_URL:"http://127.0.0.1:8000",
    VERSION:"1.17.0",
    EVENT_ID: 11,
    EVENT_SLUG: 'cumbrecafe',
    //OPENPAY_DASHBOARD_URL: 'https://dashboard.openpay.mx',
    //OPENPAY_MERCHANT_ID: 'mwca4slsgxnijzuhttwm',
    OPENPAY_DASHBOARD_URL: 'https://sandbox-dashboard.openpay.mx',
    OPENPAY_MERCHANT_ID: 'mh16ywiiyvfwbyr1q7ui'
};