import React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@material-ui/core/CssBaseline';

import { BrowserRouter as Router } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import styles from './theme/styles.js';
import conventio_theme from './theme/conventio_theme.js';
import { MuiThemeProvider } from '@material-ui/core/styles';
import ConventioRouter from './components/navigation/ConventioRouter.js';

class App extends React.Component {
  
  state = {
    hasSession: false
  };

  componentDidMount(){
    
    
  }
  
  render(){
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={conventio_theme}>
        <Router >
          <div className={classes.root}>
            <CssBaseline />
              <main className={classes.content}>
                <ConventioRouter></ConventioRouter>
              </main>
          </div>
        </Router>
      </MuiThemeProvider>
    );
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(App);