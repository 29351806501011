import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import styles from '../../theme/styles.js';
import header from '../../assets/images/logo_tra.png';
import openpay_tiendas from '../../assets/images/openpay_tiendas.png';
import openpay_credit_cards from '../../assets/images/openpay_credit_cards.png';
import openpay_debit_cards from '../../assets/images/openpay_debit_cards.png';
import { Grid, Paper, RadioGroup, Radio, FormControlLabel, FormLabel } from '@material-ui/core';
import { user_data } from './user_data.js';
import { config } from '../../services/config.js';
import validateEmail from '../utils/validateEmail.js';
import style_constants from '../../theme/style_constants.js';

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import FilledInput from '@material-ui/core/FilledInput';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import SecurityIcon from '@material-ui/icons/Security';
import CircularProgress from '@material-ui/core/CircularProgress';

import Hidden from '@material-ui/core/Hidden';
import ServicesFacade from '../../services/ServicesFacade.js';

function Transition(props) {
    return <Slide direction="up" {...props} />;
  }

class HomeScreen extends React.Component{

    state={
        tiene_beca: "0",
        becario_codigo_correcto:'BECA201921',
        errorFields:[],
        loading:false,
        error_open: false,
        error_msg:"",
        guest_groups: [],
        company_option: '',
        confirmation_open: false,
        openpay_open: false,
        register_loading: false,
        confirmation_loading: false,
        display_guests_lists: true,
        display_manual_registration: false,
        totalGuests: 0,
        checkedGuests: 0,
        companions_count: "0",
        event_title: "Conventio Event 2020",
        event_location: "29 y 30 de Junio 2020",
        companions_count_list: [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
        ],
        registration_type: {id:0,user_type:""},
        registration_types:[],
        access_type: {id:0,inscription_type:"",amount:0},
        access_types:[],
        times: [
            "00:00",
            "01:00",
            "02:00",
            "03:00",
            "04:00",
            "05:00",
            "06:00",
            "07:00",
            "08:00",
            "09:00",
            "10:00",
            "11:00",
            "12:00",
            "13:00",
            "14:00",
            "15:00",
            "16:00",
            "17:00",
            "18:00",
            "19:00",
            "20:00",
            "21:00",
            "22:00",
            "23:00"
            
        ],
        interested_in_physical_activites: "1"
    }

    constructor() {
        super()
        this.top = React.createRef();
        this.guest_list_ref = React.createRef();
        this.registration_ref = React.createRef();
        this.manual_registration_ref = React.createRef();
        this.companiesGridRef = React.createRef();
    }
    
    scroll(ref) {
        ref.current.scrollIntoView({behavior: 'smooth'})
    }
    
    componentWillMount(){
        //TODO: Traerse del server la config del evento
        //TODO: Traerse del server el listado de usuarios
        this.setState({loading: false});
        //Transformamos el listado de usuarios a la estructura que necesitamos
        for(var i=0; i<user_data.length; i++){
            var user = user_data[i];
            //Clean the info
            user.company_name = user.company_name.trim();
            this.loadIntoCategory(user);
        }
        //Seleccionar el primero por default
        if(this.state.guest_groups.length>0){
            //this.state.guest_groups[0].is_selected = true;

            this.setState({guest_groups: this.state.guest_groups});
            //this.setState({current_guest_group: this.state.guest_groups[0]});
        }
        var parent = this;
        setTimeout(function(){
            //parent.retrieveUsersGuestsStatus();
            //parent.scroll(parent.top);
        },1000);

        //Load access types

        new ServicesFacade().retrieveAccessTypes(config.EVENT_ID,
            (successWithResponse)=>{
                this.setState({access_types: successWithResponse.data});
                this.setState({access_type: this.state.access_types[0]});
            },
            (status,error)=>{
                console.log("Error at retrieving access types:: "+JSON.stringify(error));
            }
        );

        //Load user types (registration types)
        new ServicesFacade().retrieveUserTypes(config.EVENT_ID,
            (successWithResponse)=>{
                this.setState({registration_types: successWithResponse.data});
                this.setState({registration_type: this.state.registration_types[0]});
            },
            (status,error)=>{
                console.log("Error at retrieving user types:: "+JSON.stringify(error));
            }
        );
    }

    componentDidMount () {
        /*window.OpenPay.setId(config.OPENPAY_MERCHANT_ID);
        //Solo para pruebas
        if(config.OPENPAY_DASHBOARD_URL.indexOf("sandbox")!==-1){
            window.OpenPay.setSandboxMode(true);
        }else{
            window.OpenPay.setSandboxMode(false);
        }*/

        //Esto por si es un redirect de pagos
        
        var search = this.props.location.search;
        if(search && search.indexOf("?id=")!==-1){
            var id = search.replace("?id=","");
            new ServicesFacade().putPaymentConfirmation(id,
                (successWithResponse)=>{
                    var res = successWithResponse.data;
                    this.showOpenPayTicket('confirm', res.user.id, res.user, res.event.infos[0], res.payment);
                },
                (status, error)=>{
                    console.log("ERROR al confirmar transacción:: "+JSON.stringify(error));
                })
        }else{
            if(search && search.indexOf("user_id="!==-1) && search.indexOf("event_id="!==-1)){
                var query_string = search.replace("?","").split("&");

                var user_id=0;
                var event_id=0;
                for(var i=0;i<query_string.length;i++){
                    if(query_string[i].indexOf("user_id=")!==-1){
                        user_id = query_string[i].split("=")[1];
                    }else if(query_string[i].indexOf("event_id=")!==-1){
                        event_id = query_string[i].split("=")[1];
                    }
                }
                
                //Esto por si nos mandan user y event id, que es para volver a cobrar
                new ServicesFacade().retrieveAttendance(user_id, event_id, 'ES',
                    (successWithResponse)=>{
                        var res = successWithResponse.data;

                        this.setState({
                            first_name: res.user.first_name,
                            last_name_1: res.user.last_name,
                            email: res.user.email,
                            phone: res.user.profile.phone,
                            direccion: res.user.profile.street + ' ' + 
                            (res.user.profile.suburb!=null?res.user.profile.suburb:'') + 
                            (res.user.profile.number!=null?res.user.profile.number:'') ,
                            access_type: {
                                id: res.access.id,
                                inscription_type: res.access.inscription_type,
                                amount: res.access.amount
                            }
                        });

                        this.showPaypalTicket('charge',res.user.id);
                        this.showOpenPayTicket('charge', res.user.id);

                    },
                    (status, error)=>{
                        console.log("ERROR al querer cobrar :: "+JSON.stringify(error));
                    }
                );
            }
        }
      

    }

    changeGuestStatusByEmail(email, invitation_status){
        for(var i=0;i<this.state.guest_groups.length;i++){
            var guest_group = this.state.guest_groups[i];
            for(var x=0;x<guest_group.guests.length;x++){
                var single_guest = guest_group.guests[x];
                if(this.buildEmail(single_guest)===email){
                    single_guest.invitation_status = invitation_status;
                    this.state.checkedGuests = this.state.checkedGuests+1;
                }
            }
        }
        if(this.state.checkedGuests>=this.state.totalGuests){
            this.setState({guest_groups: this.state.guest_groups});
        }
    }

    retrieveUsersGuestsStatus(){
        var totalGuests = 0;
        for(var i=0; i<this.state.guest_groups.length; i++){
            var guest_group = this.state.guest_groups[i];
            totalGuests += guest_group.guests.length;
        }
        this.state.totalGuests = totalGuests;

        for(var i=0; i<this.state.guest_groups.length; i++){
            var guest_group = this.state.guest_groups[i];
            for(var x=0;x<guest_group.guests.length;x++){
                var guest = guest_group.guests[x];
                var email = this.buildEmail(guest);
                new ServicesFacade().retrieveGuestInvitationStatus(
                    email,
                    (email)=>{
                        console.log("Email confirmed:: "+email);
                        this.changeGuestStatusByEmail(email, 'confirmed');
                    },
                    (status, email)=>{
                        if(status==404){
                            console.log("Email invited:: "+email);
                            this.changeGuestStatusByEmail(email, 'invited_checked');
                        }
                    },
                );
            }
        }
    }

    loadIntoCategory(user){
        var group_founded = false;
        for(var i=0; i<this.state.guest_groups.length; i++){
            var guest_group = this.state.guest_groups[i];
            if(this.parseCategory(user.inscription_type)==guest_group.category){
                //A menos que ya tenga la compañía, la agregamos
                if(!guest_group.companies.includes(user.company_name)){
                    guest_group.companies.push(user.company_name);
                }
                guest_group.guests.push(user);
                group_founded = true;
            }
        }

        if(!group_founded){
            //Trim the company of the user
            user.company_name = user.company_name.trim();
            var guest_group = {
                category: this.parseCategory(user.inscription_type.trim()),
                companies:[user.company_name.trim()],
                guests:[user]
            };
            this.state.guest_groups.push(guest_group);
        }

    }

    parseCategory(inscription_type){
        if(inscription_type.indexOf('|')!==-1){
            return inscription_type.split('|')[1];
        }

        return inscription_type;
    }

    clearRegistrationForm(){
        this.setState({user_id:'',first_name:'', last_name_1: '', last_name_2: '', company: '', name_to_confirm: '', country: '', genero: '', direccion: '', phone: '', email: '', fecha_nacimiento: '', registration_type: this.state.registration_types[0], access_type: this.state.access_types[0], companions_count:'', companions_names: '', arrival_place: '', arrival_date: '', arrival_airline: '', arrival_flight_number: '', arrival_time: '', return_place: '', return_date: '', return_airline: '', return_flight_number: '', return_time: '', contact_number: '', alergies: '', food_restrictions: '', interested_in_physical_activites: '1'});
        var parent=this;
        setTimeout(function(){
            parent.scroll(parent.top);
        },500);
    }

    handleCompanyChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    
    handleRegistrationTypeChange(selectedRegistrationType){
        var registration_type_id = selectedRegistrationType.target.value;
        for(var i=0;i<this.state.registration_types.length;i++){
            if(this.state.registration_types[i].id==registration_type_id){
                this.setState({registration_type: this.state.registration_types[i]});
            }
        }

    }

    handleAccessTypeChange(selectedAccessType){
        var access_type_id = selectedAccessType.target.value;
        for(var i=0;i<this.state.access_types.length;i++){
            if(this.state.access_types[i].id==access_type_id){
                this.setState({access_type: this.state.access_types[i]});
            }
        }
    }

    selectCompany(company_option){
        this.setState({company_option: company_option})
        var parent = this;
        setTimeout(function(){
            parent.scroll(parent.registration_ref);
        },200);
    }

    handleCategoryChange(guest_group){
        if(!guest_group){
            guest_group = this.state.guest_groups[0];
        }
        this.clearGuestSelection(guest_group);
        //Unselect all
        for(var i=0;i<this.state.guest_groups.length;i++){
            var g = this.state.guest_groups[i];
            g.is_selected = false;
        }
        guest_group.is_selected = true;
        this.setState({current_guest_group: guest_group, company_option: ''});
        this.scroll(this.companiesGridRef);
    }

    handleRegistration(){
        
        if(this.state.register_loading) return;
        if(this.isValid()){

            new ServicesFacade().retrieveUserInEvent(this.state.email,
                (success)=>{
                    this.displayErrorSnackbar("El email "+this.state.email+" ya ha sido registrado previamente");
                    return false;
                },
                (status, error)=>{
                    if(status==404){
                        this.setState({register_loading: true});

                        var data = this.buildRegistrationData('new');

                        new ServicesFacade().postRegistration(data, null, 
                            (resultWithStatus)=>{

                                var user_id = resultWithStatus.data.id;
                                var extra_fields_data = this.buildExtraFields();
                                new ServicesFacade().postExtraFields(extra_fields_data, user_id,
                                    (extraFieldsResultWithStatus)=>{

                                        //Si se debe pagar, creamos openpay button, para cobrarle
                                        if(this.state.access_type.amount===0 || this.state.tiene_beca==="1"){
                                            this.setState({register_loading: false});
                                            var name_to_confirm = this.state.first_name;
                                            if(this.state.last_name_1){
                                                name_to_confirm+=' ';
                                                name_to_confirm+=this.state.last_name_1;
                                            }
                                            if(this.state.last_name_2){
                                                name_to_confirm+=' ';
                                                name_to_confirm+=this.state.last_name_2;
                                            }
                                            this.showConfirmationDialog(name_to_confirm);
                                        }else{
                                            this.showPaypalTicket('charge',user_id);
                                            this.showOpenPayTicket('charge', user_id);
                                        }

                                    },
                                    (extraFieldsError)=>{
                                        this.setState({register_loading: false});
                                        this.displayErrorSnackbar("Error al completar registro: "+JSON.stringify(extraFieldsError));
                                    });


                            },
                            (error)=>{
                                this.setState({register_loading: false});
                                this.displayErrorSnackbar("Usuario previamente registrado");
                                //alert("ERROR:: "+JSON.stringify(error));
                            });
                    }else{
                        console.log("ERROR while validating email:: "+ error);
                    }
                });
        }
    }

    showPaypalTicket(mode, user_id){
        
        var father = this;
        
        if(mode=="charge"){

            this.setState({openpay_open: true, 
                paypal_mode: mode,
                user_id: user_id});
            
            setTimeout(function(){
                document.getElementById("paypal-button-container").innerHTML="";

                window.paypal.Button.render({
        
                    // Set your environment
            
                    env: 'sandbox', // sandbox | production
            
                    // Specify the style of the button
            
                    style: {
                        layout: 'vertical',  // horizontal | vertical
                        size:   'medium',    // medium | large | responsive
                        shape:  'rect',      // pill | rect
                        color:  'blue'       // gold | blue | silver | black
                    },
            
                    // Specify allowed and disallowed funding sources
                    //
                    // Options:
                    // - paypal.FUNDING.CARD
                    // - paypal.FUNDING.CREDIT
                    // - paypal.FUNDING.ELV
            
                    funding: {
                        allowed: [ window.paypal.FUNDING.CARD ],
                        disallowed: [ window.paypal.FUNDING.CREDIT ]
                    },
            
                    // PayPal Client IDs - replace with your own
                    // Create a PayPal app: https://developer.paypal.com/developer/applications/create
            
                    client: {
                        sandbox:    'ATC5fUpLID4rGwVrBekNF3NWolWuaCAckBs9p9FxeiURJH4qGH85FBeBa9e8vZLmrHFKrEHp5W8tJvGs',
                        production: 'Af6Qm5KJMsG9yWhKlvT6JwKWHJzU7bPdA5TAUB41iuiCfLOwLR2sbeDVwItJGjXmYgA1Awu1NzKzNuwi'
                    },
            
                    payment: function(data, actions) {
                        return actions.payment.create({
                            payment: {
                                transactions: [
                                    {
                                        amount: { 
                                        total: father.state.access_type.amount, 
                                        currency: "MXN"
                                        },
                                        description: father.state.access_type.inscription_type,
                                        custom: father.state.email
                                    }
                                ]
                            }
                        });
                    },
            
                    onAuthorize: function(data, actions) {
                        return actions.payment.execute().then(function() {
                            father.paypalPaymentCompleted(data);
                        });
                    }
            
                }, '#paypal-button-container');
            },500);

           
        }
        
    }

    paypalPaymentCompleted(data){
        var transaction_id = data.paymentID;
        new ServicesFacade().putPaypalPaymentConfirmation
            (config.EVENT_ID, this.state.user_id, JSON.stringify(data), transaction_id, this.state.access_type.amount, 
            (success)=>{
                this.setState({paypal_mode: 'confirm'});
            },
            (error)=>{
                console.log("ERROR:: "+JSON.stringify(error));
            })
    }

    showOpenPayTicket(mode, user_id, user, event, payment){
        if(mode == "charge"){

            new ServicesFacade().retrieveOpenPayRedirectLink(this.state.access_type.id,user_id,
                (successWithResponse)=>{
                    this.setState({register_loading: false});
                    var redirect_url = successWithResponse.data.online_payment.redirect_url;//Esta estará en un boton que abrirá la pasarela de pago open pay
                    var barcode_url = successWithResponse.data.store_payment.barcode_url;
                    var receipt_url = config.OPENPAY_DASHBOARD_URL+"/paynet-pdf/"+config.OPENPAY_MERCHANT_ID+"/"+successWithResponse.data.store_payment.reference;
                    this.setState({user_id: user_id,
                                    openpay_open: true, 
                                    openpay_mode: mode, 
                                    openpay_redirect_url: redirect_url,  
                                    openpay_barcode_url: barcode_url,
                                    openpay_receipt_url: receipt_url});
                },
                (status, error)=>{
                    console.log("ERROR obtaining open pay redirect link:: "+JSON.stringify(error));
                })
        }else if(mode=="confirm"){
            
            this.setState({user_id: user_id,openpay_open: true, openpay_mode: mode, openpay_confirmation_user: user, openpay_confirmation_event: event, openpay_confirmation_payment:payment});
        }
    }

    retrieveAccessTypeDescription(id){
        for(var i=0;i<this.state.access_types.length;i++){
            var at = this.state.access_types[i];
            if(at.id==id){
                return at.inscription_type;
            }
        }

    }
    
    handleConfirmation(guest){
        console.log("Confirmation for guest:: "+JSON.stringify(guest));
        this.setState({confirmation_loading: true});
        var name_to_confirm = guest.first_name;
        if(guest.last_name){
            name_to_confirm+=' ';
            name_to_confirm+=guest.last_name;
        }
        
        var data = this.buildRegistrationData('confirm', guest);
        new ServicesFacade().postRegistration(data, null, 
        (resultWithStatus)=>{
            this.setState({confirmation_loading: false});
            this.showConfirmationDialog(name_to_confirm);
            this.changeGuestStatusByEmail(this.buildEmail(guest), "confirmed");
        },
        (error)=>{
            alert("ERROR:: "+JSON.stringify(error));
        });
    }

    handleOpenPayClose(clear){
        this.setState({openpay_open: false, register_loading: false});
        if(clear){
            this.clearRegistrationForm();
        }
    }

    handleConfirmationClose(){
        
        this.setState({ confirmation_open: false });
        this.setState({company_option: undefined});
        this.setState({current_guest_group: undefined});
        this.showGuestList();
        this.state.guest_groups.map(function(item){
            item.is_selected=false;
        });
        this.setState({guest_groups:this.state.guest_groups});
        this.showGuestList();
        this.clearRegistrationForm();
        
    }

    handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({error_open: false});
        
    }

    buildRegistrationData(reg_type, guest){

        var guest_data=undefined;
        
        if(reg_type=="new"){
            guest_data = {nombre: this.state.first_name,
                apellido_paterno: this.state.last_name_1,
                apellido_materno: this.state.last_name_2?this.state.last_name_2:"",
                company: this.state.company,
                email: this.state.email,
                tipo_usuario: this.state.registration_type
            };
        }else{
            guest_data = {
                nombre: guest.first_name,
                apellido_paterno: "",
                apellido_materno: "",
                company: guest.company_name,
                email: this.state.email,
                tipo_usuario: guest.inscription_type
            };
        }

        var guest_email = this.buildEmail(guest_data);

        //Si es becario, set del access_type
        if(this.state.tiene_beca==="1"){
            this.state.access_type = this.state.access_types[1];
        }

        var data = {
            titulo: "",
            nombre: guest_data.nombre,
            apellido_paterno: guest_data.apellido_paterno,
            apellido_materno: guest_data.apellido_materno,
            genero: this.state.genero,
            especialidad: "",
            sub_especialidad: "",
            telefono_fijo: this.state.phone,
            celular: "",
            telefono_contacto: "",
            correo_electronico: guest_email,
            estado: "",
            direccion: this.state.direccion,
            password: guest_email,
            pais: "",
            tipo_inscripcion_id: this.state.access_type.id,
            tipo_inscripcion: this.retrieveAccessTypeDescription(this.state.access_type.id),
            tipo_usuario_id: this.state.registration_type.id,
            tipo_usuario: guest_data.tipo_usuario.user_type,
            paypal_amount: "",
            paypal_raw_response: "",
            paypal_transaction_id: ""

        }

        return data;
    }

    buildExtraFields(){
        var data = {
            extra_fields: [
                {
                    field_slug: "fecha_nacimiento",
                    field_value: this.state.fecha_nacimiento?this.state.fecha_nacimiento:"01/01/1900"
                },
                {
                    field_slug: "tiene_beca",
                    field_value: this.state.tiene_beca?this.state.tiene_beca:""
                }

            ]
        }

        return data;
    }

    buildEmail(guest){
        /*var nombre = guest.nombre?guest.nombre:guest.first_name;
        var ap = guest.apellido_paterno?guest.apellido_paterno:"";
        var am = guest.apellido_materno?guest.apellido_materno:"";
        var guest_account = config.EVENT_ID+"_"+
                            nombre.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")+"_"+
                            ap.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")+"_"+
                            am.trim().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")+"_"+
                            "@conventioguest.co";
        return guest_account;*/
        return guest.email;
      }
    

    showConfirmationDialog(name_to_confirm){
        this.setState({confirmation_open: true, name_to_confirm: name_to_confirm});
    }

    

    renderCategoriesButtons(classes, device){
        var parent = this;
        var index = 1;
        this.state.guest_groups.map(function(item){
            item.index = index;
            index++;
        });
        var categoriesButtons = 
            this.state.guest_groups.map(function(item){
                return(
                    <span style={{padding: '2px'}}>
                        <Button key={item.category} 
                                variant={item.is_selected?'contained':'outlined'}
                                style={{color: item.is_selected?'white':style_constants.button_gray,marginBottom: '2px', borderColor:style_constants.button_gray}}
                                color='primary'
                                onClick={()=>parent.handleCategoryChange(item)}
                                className={classes.button}>
                            {item.category}
                        </Button>
                        {(item.index%2==0 && device=='mobile') && <br/>}
                    </span>
                );
            });
        
        return categoriesButtons;
    }

    renderCompaniesGrid(classes, platform){

        var block_size;
        var spacing;
        if(platform=="desktop"){
            block_size=2;
            spacing=5;
        }else if(platform=="tablet"){
            block_size=5;
            spacing=1;
        }else{
            block_size=12;
            spacing=1;
        }

        var parent = this;
        var companiesGrid = this.state.current_guest_group.companies.map(function(item){
            return (
                <Grid item xs={block_size} style={{margin: spacing+'px',padding: spacing+'px'}}>
                    <Button
                        onClick={()=>{parent.selectCompany(item)}}
                        variant={parent.state.company_option==item?'contained':'outlined'}
                        color="primary"
                        style={{borderColor:style_constants.button_gray, color: parent.state.company_option==item?'white':style_constants.button_gray, minWidth: '100%'}}
                        >
                        {item}
                    </Button>
                </Grid>);
        });
        
        return <Grid container direction="row">
                    {companiesGrid}
                </Grid>;

    }

    renderDropdownCompanies(classes){

        var companiesDropdown = 
            <FormControl variant="filled" className={classes.formControl}>
                <InputLabel htmlFor="filled-company_option-native-simple">Compañía de procedencia</InputLabel>
                <Select
                    native
                    value={this.state.company_option}
                    onChange={this.handleCompanyChange('company_option')}
                    input={<FilledInput name="company_option" id="filled-company_option-native-simple" />}
                >
                    <option value={""}></option>
                    {this.state.current_guest_group.companies.map(function(item){
                        return (<option value={item}>{item}</option>);
                    })}
                </Select>
            </FormControl>;
        
        return companiesDropdown;
    }

    selectGuest(group, guest){
        if(guest.invitation_status=='confirmed' || guest.invitation_status=="invited") return;
        this.clearGuestSelection(group);
        for(var i=0;i<group.guests.length;i++){
            if(group.guests[i].id==guest.id){
                group.guests[i].is_selected = true;
            }
        }
        this.setState({current_guest_group: group});
    }
    
    clearGuestSelection(group){
        for(var i=0;i<group.guests.length;i++){
            group.guests[i].is_selected = false;
        }
    }

    retrieveDirectoryLetterForGroup(guest_group, guest){

        if(!guest_group.directoryLetters){
            guest_group.directoryLetters = [];
        }
        var first_letter = guest.first_name.charAt(0).toUpperCase();
        if(!guest_group.directoryLetters.includes(first_letter)){
            guest_group.directoryLetters.push(first_letter);
            return (<Typography variant='h2'>{first_letter}</Typography>);
        }else{
            return (<div></div>);
        }

    }

    showManualRegistration(){
        this.scroll(this.top);
        this.setState({display_manual_registration: true, display_guests_lists: false})
    }

    showGuestList(){
        this.scroll(this.top);
        this.setState({display_manual_registration: false, display_guests_lists: true})
    }

    renderMobileGuestsTable(classes){
        var parent = this;
        var filtered_guests = [];
        for(var i=0;i<this.state.current_guest_group.guests.length;i++){
            var guest = this.state.current_guest_group.guests[i];
            if(guest.company_name==this.state.company_option){
                filtered_guests.push(guest);
            }
        }
        //Limpiamos el arreglo de letras directorio para que se recalcule contra los usuarios filtrados
        if(parent.state.current_guest_group.directoryLetters){
            parent.state.current_guest_group.directoryLetters = [];
        }
        var guestsTable = 
            <Table style={screen_styles.table}>
                <TableBody>
                    {filtered_guests.map(function(item){
                        return (
                            <TableRow key={item.id} 
                                        style={{cursor: 'pointer'}}
                                        onClick={()=>parent.selectGuest(parent.state.current_guest_group, item)}>
                                <TableCell style={item.is_selected?screen_styles.selectedCell:{}}>
                                    {item.first_name} {item.last_name} {parent.state.current_guest_group.category}
                                    {item.invitation_status=='invited'? 
                                        <div>
                                            <Typography variant='body1'>
                                                Confirmando... 
                                                <CircularProgress size={20} color="primary"/>
                                            </Typography>
                                        </div>
                                        :
                                        ''}
                                    {!item.is_selected && item.invitation_status=='confirmed'?
                                        <Typography variant='body1'>
                                            Confirmado
                                        </Typography>:
                                        ''}
                                        {item.is_selected &&
                                            
                                            <div className={classes.progress_button_wrapper}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={()=>parent.handleConfirmation(item)}
                                                    style={{fontWeight: '900', color: 'white'}}>
                                                    CONFIRMAR
                                                </Button>
                                                {parent.state.confirmation_loading && <CircularProgress size={24} style={{color: 'white'}} className={classes.buttonProgress} />}
                                            </div>
                                            
                                        }
                                    {!item.is_selected &&
                                        <div style={{width: '10px'}}></div>
                                    }
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>;
        
        return guestsTable;
    }
    renderGuestsTable(classes){
        var parent = this;
        var filtered_guests = [];
        for(var i=0;i<this.state.current_guest_group.guests.length;i++){
            var guest = this.state.current_guest_group.guests[i];
            if(guest.company_name==this.state.company_option){
                filtered_guests.push(guest);
            }
        }
        //Limpiamos el arreglo de letras directorio para que se recalcule contra los usuarios filtrados
        if(parent.state.current_guest_group.directoryLetters){
            parent.state.current_guest_group.directoryLetters = [];
        }
        var guestsTable = 
            <Table style={screen_styles.table}>
                <TableBody>
                    {filtered_guests.map(function(item){
                        return (
                            <TableRow key={item.id} 
                                        style={{cursor: 'pointer'}}
                                        onClick={()=>parent.selectGuest(parent.state.current_guest_group, item)}>
                                <TableCell>
                                    {parent.retrieveDirectoryLetterForGroup(parent.state.current_guest_group, item)}
                                </TableCell>
                                <TableCell style={item.is_selected?screen_styles.selectedCell:{}}>
                                    {item.first_name} {item.last_name}
                                </TableCell>
                                <TableCell style={item.is_selected?screen_styles.selectedCell:{}}>
                                    {parent.state.current_guest_group.category}
                                </TableCell>
                                <TableCell style={item.is_selected?screen_styles.selectedCell:{}}>
                                    {item.invitation_status=='invited'? 
                                        <div>
                                            <Typography variant='body1'>
                                                Confirmando... 
                                                <CircularProgress size={20} color="primary"/>
                                            </Typography>
                                        </div>
                                        :
                                        ''}
                                    {!item.is_selected && item.invitation_status=='confirmed'?
                                        <Typography variant='body1'>
                                            Confirmado
                                        </Typography>:
                                        ''}
                                        {item.is_selected &&
                                            
                                            <div className={classes.progress_button_wrapper}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={()=>parent.handleConfirmation(item)}
                                                    style={{fontWeight: '900', color: 'white'}}>
                                                    CONFIRMAR
                                                </Button>
                                                {parent.state.confirmation_loading && <CircularProgress size={24} style={{color: 'white'}} className={classes.buttonProgress} />}
                                            </div>
                                            
                                        }
                                    {!item.is_selected &&
                                        <div style={{width: '200px'}}></div>
                                    }
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>;
        
        return guestsTable;
    }

    render(){
        const { classes } = this.props;
        return(

            <div className={classes.root}>
                <div ref={this.top}></div>
                <AppBar position="fixed" color="default" style={screen_styles.header}>
                <Toolbar>
                    <div style={{width: '100%'}}>
                        <Hidden  xsDown implementation="css">
                            <Grid container direction="row" spacing={16}>
                                <Grid item sm={3} style={screen_styles.logo}>
                                    <img style={{ width: '42%'}} src={header} alt="Header logo" />
                                </Grid>
                                <Grid item sm={9}>
                                    <Grid container direction="column" style={screen_styles.event_title}>
                                        <Grid item xs={12}>
                                            <Typography variant='h3' style={{color: 'white'}}>
                                                {this.state.event_title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h4' style={{color:'white',marginBottom: '20px'}}>
                                                {this.state.event_location}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Hidden>
                        <Hidden  smUp implementation="css">
                            <Grid container direction="row" spacing={16}>
                                <Grid item xs={3} style={screen_styles.logo}>
                                    <img style={{ width: '100%'}} src={header} alt="Header logo" />
                                </Grid>
                                <Grid item xs={9}>
                                    <Grid container direction="column" style={screen_styles.event_title}>
                                        <Grid item xs={12}>
                                            <Typography variant='h3' style={{color:'white', fontSize: '1.5rem'}}>
                                                {this.state.event_title}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant='h4' style={{color:'white', fontSize: '1rem'}}>
                                                {this.state.event_location}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Hidden>
                    </div>
                    
                    
                </Toolbar>
                </AppBar>
                {this.state.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                {!this.state.loading && 
                <main className={classes.content} style={screen_styles.main_container}>
                    <div className={classes.toolbar} />
                    
                    <Paper style={{display: "none"}} className={classes.paper}>
                        <Grid container direction="column">
                            <Grid item xs={12}>
                                <Grid container direction="row">
                                    <div ref={this.guest_list_ref}></div>
                                    <Grid item xs={12} style={screen_styles.section_title}>
                                        <Typography variant='h5'>
                                            SELECCIONA TU PROCEDENCIA
                                        </Typography>
                                    </Grid>
                                        <Hidden xsDown implementation="css">
                                            <Grid item xs={12} style={screen_styles.section_title}>
                                                {this.renderCategoriesButtons(classes, 'desktop')}
                                            </Grid>
                                        </Hidden>
                                        <div ref={this.companiesGridRef}></div>
                                        <Hidden smUp implementation="css">

                                            <Grid item xs={12} style={screen_styles.section_title}>
                                                <span style={{paddingLeft: '5vw'}}>
                                                    {this.renderCategoriesButtons(classes, 'mobile')}
                                                </span>
                                            </Grid>
                                        </Hidden>
                                        
                                    {this.state.current_guest_group &&
                                        <Grid container direction="row">
                                            <Grid item xs={12} style={{paddingTop: '35px'}}>
                                            </Grid>
                                            <Grid item xs={12} style={screen_styles.section_title}>
                                                
                                                <Typography variant='h5'>
                                                    ¿DE DÓNDE NOS VISITAS?    
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={{paddingLeft: '3vw'}}>
                                                    <Hidden only={['xs','sm']} implementation="css">
                                                        {this.renderCompaniesGrid(classes, 'desktop')}
                                                    </Hidden>
                                                    <Hidden only={['xs','md','lg','xl']} implementation="css">
                                                        {this.renderCompaniesGrid(classes, 'tablet')}
                                                    </Hidden>
                                                    <Hidden only={['sm','md','lg','xl']} implementation="css">
                                                        {this.renderCompaniesGrid(classes, 'smartphone')}
                                                    </Hidden>
                                                </div>
                                            </Grid>
                                            
                                            <Grid item xs={12} style={{paddingTop: '35px'}}>
                                            </Grid>
                                            {this.state.company_option!='' &&
                                                <div style={{minHeight: '30vh'}}>
                                                    <Grid item xs={12} style={screen_styles.section_title}>
                                                    <div ref={this.registration_ref} ></div>
                                                        <Typography variant='h5'>
                                                            PRESIONA TU NOMBRE Y CONFIRMA TU ASISTENCIA
                                                        </Typography>
                                                    </Grid>    
                                                    <Hidden xsDown implementation="css">
                                                        <Grid item xs={12} style={screen_styles.section_title}>
                                                        {this.renderGuestsTable(classes)}
                                                        </Grid>
                                                    </Hidden>
                                                    <Hidden smUp implementation="css">
                                                        <Grid item xs={12} style={screen_styles.section_title}>
                                                        {this.renderMobileGuestsTable(classes)}
                                                        </Grid>
                                                    </Hidden>
                                                    
                                                    <Grid item xs={12} style={{marginTop: '20px'}}>
                                                        <Grid container direction="row">
                                                            <Grid item xs={2}></Grid>
                                                            <Grid item xs={10}>
                                                                <Grid container direction="row">
                                                                    <Grid item xs={9}>
                                                                        <Typography variant='body1'>
                                                                            Si no encontraste tu nombre &nbsp;
                                                                            <Button
                                                                                color="primary"
                                                                                variant="contained"
                                                                                onClick={()=>this.showManualRegistration()}
                                                                                style={{fontWeight: '700', color: 'white'}}>
                                                                                REGISTRATE AQUÍ
                                                                            </Button>
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={3}>
                                                                        <Grid container direction="row" style={{justifyContent: 'flex-end'}}>
                                                                                <Button variant="outlined"
                                                                                        onClick={()=>this.scroll(this.top)}
                                                                                        color="primary">
                                                                                    <Hidden xsDown implementation="css">
                                                                                        Subir al inicio
                                                                                    </Hidden>
                                                                                    <Hidden smUp implementation="css">
                                                                                         Subir
                                                                                    </Hidden>
                                                                                </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            
                                                        </Grid>
                                                        
                                                    </Grid>
                                                </div>
                                            }
                                        </Grid>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                    <br/>
                    
                    <Paper className={classes.paper}>
                        <Grid container direction="column">
                            <Grid item xs={12}>
                                
                                <Grid container direction="row">
                                    <Grid item xs={12} style={{textAlign: 'center'}}>
                                        <Typography variant='h5' style={{fontSize: '2rem'}}>
                                            REGISTRO DE DATOS
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} style={{paddingTop: '10px'}}>&nbsp;</Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" style={screen_styles.section_title}>
                                            {/*
                                            <Grid item xs={1}>
                                                Paso 1 de 2
                                            </Grid>
                                             */}
                                            <Grid item xs={9}>
                                                <Typography variant='h5'>
                                                    Información general
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div ref={this.manual_registration_ref} ></div>
                                       <Grid container direction="row">
                                             <Grid item xs={1} sm={2}></Grid>
                                             <Grid item xs={10} sm={8}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        margin="dense"
                                                        id="first_name"
                                                        name="first_name"
                                                        label="*Nombre (s)"
                                                        type="text"
                                                        variant="filled"
                                                        value={this.state.first_name}
                                                        onChange={(e) => this.setState({'first_name':e.target.value})}
                                                        onBlur={()=>this.isFieldValid('first_name')}
                                                        error={this.isFieldWithError('first_name')}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        margin="dense"
                                                        id="last_name_1"
                                                        name="last_name_1"
                                                        label="*Apellido (s)"
                                                        type="text"
                                                        variant="filled"
                                                        value={this.state.last_name_1}
                                                        onChange={(e) => this.setState({'last_name_1':e.target.value})}
                                                        onBlur={()=>this.isFieldValid('last_name_1')}
                                                        error={this.isFieldWithError('last_name_1')}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        margin="dense"
                                                        id="phone"
                                                        name="phone"
                                                        label="*Teléfono"
                                                        type="text"
                                                        variant="filled"
                                                        value={this.state.phone}
                                                        onChange={(e) => this.setState({'phone':e.target.value})}
                                                        onBlur={()=>this.isFieldValid('phone')}
                                                        error={this.isFieldWithError('phone')}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        margin="dense"
                                                        id="direccion"
                                                        name="direccion"
                                                        label="*Ciudad"
                                                        type="text"
                                                        variant="filled"
                                                        value={this.state.direccion}
                                                        onChange={(e) => this.setState({'direccion':e.target.value})}
                                                        onBlur={()=>this.isFieldValid('direccion')}
                                                        error={this.isFieldWithError('direccion')}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        margin="dense"
                                                        id="email"
                                                        name="email"
                                                        label="*Correo electrónico"
                                                        type="text"
                                                        variant="filled"
                                                        value={this.state.email}
                                                        onChange={(e) => this.setState({'email':e.target.value})}
                                                        onBlur={()=>this.isFieldValid('email')}
                                                        error={this.isFieldWithError('email')}
                                                        fullWidth
                                                    />
                                                </Grid>
                                                
                                                <Grid item xs={12}>
                                                    <Grid container direction="row">
                                                        <Grid style={{alignSelf: 'center'}} item xs={12} sm={6}>
                                                            <FormLabel component="legend">*¿Requiere factura?</FormLabel>
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <RadioGroup
                                                                aria-label="genero"
                                                                name="genero"
                                                                style={{textAlign: 'left', flexDirection: 'row'}}
                                                                className={classes.group}
                                                                value={this.state.genero}
                                                                onChange={this.handleCompanyChange('genero')}
                                                            >
                                                                <FormControlLabel
                                                                style={{flexDirection: 'row'}}
                                                                value="1"
                                                                control={<Radio color="primary" />}
                                                                label="Si"
                                                                labelPlacement="start"
                                                                />
                                                                <FormControlLabel
                                                                style={{flexDirection: 'row'}}
                                                                value="0"
                                                                control={<Radio color="primary" />}
                                                                label="No"
                                                                labelPlacement="start"
                                                                />
                                                            </RadioGroup>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                </Grid>
                                             <Grid item xs={1} sm={2}></Grid>
                                       </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} style={{paddingTop: '10px'}}>&nbsp;</Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" style={screen_styles.section_title}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5'>
                                                    Tipo de Usuario
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" style={screen_styles.section_title}>
                                            <Grid item xs={1} sm={2}></Grid>
                                            <Grid item xs={10} sm={8}>
                                                <FormControl variant="filled" className={classes.formControl}>
                                                    <InputLabel htmlFor="filled-registration_type-native-simple">Tipo de Usuario</InputLabel>
                                                    <Select
                                                        native
                                                        value={this.state.registration_type.id}
                                                        onChange={this.handleRegistrationTypeChange.bind(this)}
                                                        input={<FilledInput name="registration_type" id="filled-registration_type-native-simple" />}
                                                    >
                                                        {this.state.registration_types.map(function(item){
                                                            return (<option value={item.id}>{item.user_type}</option>);
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={1} sm={2}></Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" style={screen_styles.section_title}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5'>
                                                    ¿Cuentas con algún tipo de beca?
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" style={screen_styles.section_title}>
                                            <Grid item xs={0} sm={3}>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                <RadioGroup
                                                    aria-label="tiene_beca"
                                                    name="tiene_beca"
                                                    style={{textAlign: 'left', flexDirection: 'row'}}
                                                    className={classes.group}
                                                    value={this.state.tiene_beca}
                                                    onChange={this.handleCompanyChange('tiene_beca')}
                                                >
                                                    <FormControlLabel
                                                    style={{flexDirection: 'row'}}
                                                    value="1"
                                                    control={<Radio color="primary" />}
                                                    label="Sí"
                                                    labelPlacement="start"
                                                    />
                                                    <FormControlLabel
                                                    style={{flexDirection: 'row'}}
                                                    value="0"
                                                    control={<Radio color="primary" />}
                                                    label="No"
                                                    labelPlacement="start"
                                                    />
                                                </RadioGroup>
                                            </Grid>
                                            <Grid item xs={12} sm={3}>
                                                {this.state.tiene_beca==="1" && 
                                                    <TextField
                                                        margin="dense"
                                                        id="becario_codigo"
                                                        name="becario_codigo"
                                                        label="*Código de becario"
                                                        type="text"
                                                        variant="filled"
                                                        value={this.state.becario_codigo}
                                                        onChange={(e) => this.setState({'becario_codigo':e.target.value})}
                                                        onBlur={()=>this.isFieldValid('becario_codigo')}
                                                        error={this.isFieldWithError('becario_codigo')}
                                                        fullWidth
                                                    />
                                                }
                                            </Grid>
                                            <Grid item xs={0} sm={3}>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    
                                    <Grid item xs={12} style={{paddingTop: '10px'}}>&nbsp;</Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" style={screen_styles.section_title}>
                                            <Grid item xs={9}>
                                                <Typography variant='h5'>
                                                    Tipo de acceso
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <div ref={this.manual_registration_ref} ></div>
                                    <Grid item xs={12}>
                                        <Grid container direction="row" style={screen_styles.section_title}>
                                            <Grid item xs={1} sm={1}></Grid>
                                            
                                            {this.state.tiene_beca==="1" && 
                                                <Grid item xs={10} sm={10}>
                                                <Typography variant="body1">
                                                    Tipo de acceso: {this.state.access_types[1].inscription_type}
                                                </Typography>
                                                </Grid>
                                            }

                                            {this.state.tiene_beca==="0" && 
                                                <Grid item xs={10} sm={10}>
                                                    <FormControl variant="filled" className={classes.formControl}>
                                                        <InputLabel htmlFor="filled-access_type-native-simple">Seleccione su tipo de acceso</InputLabel>
                                                        <Select
                                                            native
                                                            value={this.state.access_type.id}
                                                            onChange={this.handleAccessTypeChange.bind(this)}
                                                            input={<FilledInput name="access_type" id="filled-access_type-native-simple" />}
                                                            >
                                                            {this.state.access_types.map(function(item){
                                                                return (<option value={item.id}>{item.inscription_type}</option>);
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            }   

                                            <Grid item xs={1} sm={1}></Grid>
                                            <Grid item xs={9}>
                                                {this.state.access_type.amount!==0 && this.state.tiene_beca==="0" &&  
                                                    <Typography style={{textAlign: 'right', fontSize: '11px', color: 'gray'}}>
                                                        En el siguiente paso podrás realizar tu pago en línea
                                                    </Typography>
                                                }
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={12} style={{paddingTop: '10px'}}>&nbsp;</Grid>
                                    <Grid item xs={12}>
                                        <Grid container direction="row">
                                            <Grid item xs={1} sm={2}>
                                            </Grid>
                                            <Grid item xs={10} sm={8}>
                                                <Grid container direction="row" style={{justifyContent: 'center'}}>
                                                    <div style={{width: '100%', textAlign: 'center'}} className={classes.progress_button_wrapper}>
                                                        <Button 
                                                                variant="contained" 
                                                                color='primary' 
                                                                onClick={()=>this.handleRegistration()}
                                                                style={{color: 'white', width: '75%'}}
                                                                className={classes.button}>
                                                            {this.state.access_type && (this.state.access_type.amount===0 || this.state.tiene_beca==="1") && 
                                                                <span>Registrarme</span>
                                                            }
                                                            {this.state.access_type && this.state.access_type.amount!==0 && this.state.tiene_beca==="0" && 
                                                                <span>Continuar al pago</span>
                                                            }
                                                        </Button>
                                                        {this.state.register_loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={1} sm={2}>
                                            </Grid>
                                        </Grid>
                                    </Grid>



                                    <Grid item xs={12} style={{paddingTop: '10px'}}>
                                    <Typography style={{fontSize: '8px', color: 'gray'}}>
                                        Versión {config.VERSION}
                                    </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </main>
                }
                <Dialog
                    open={this.state.openpay_open}
                    TransitionComponent={Transition}
                    fullWidth={true}
                    maxWidth={'lg'}
                    keepMounted
                    onClose={()=>this.handleOpenPayClose()}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    >
                    <DialogTitle id="alert-dialog-slide-title" style={{textAlign: 'center'}}>
                        <Typography variant='h3' style={{textTransform: 'none'}}>
                            {this.state.paypal_mode=='charge' && <span>Confirma tu pago</span>}
                            {this.state.paypal_mode=='confirm' && <span>Pago recibido</span>}
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description" style={{textAlign: 'center'}}>
                            <Grid container direction="row">
                                <Grid item xs={12} sm={5}>
                                {this.state.openpay_mode=='confirm' &&
                                    <div>
                                         <Table>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="subtitle2">
                                                        {this.state.openpay_confirmation_user.first_name} {this.state.openpay_confirmation_user.last_name}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="subtitle2">
                                                        Tipo de acceso: {this.state.openpay_confirmation_payment.payment_description}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="subtitle2">
                                                        Evento: {this.state.openpay_confirmation_event.name}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    </div>
                                    }
                                    {(this.state.paypal_mode=='charge' || this.state.paypal_mode=='confirm') &&
                                    <div>
                                        <Table>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="subtitle2">
                                                        {this.state.first_name} {this.state.last_name_1}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="subtitle2">
                                                        Email: {this.state.email}, Teléfono: {this.state.phone}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="subtitle2">
                                                        Direccion: {this.state.direccion}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            <TableRow>
                                                <TableCell>
                                                    <Typography variant="subtitle2">
                                                        Tipo de acceso: {this.state.access_type.inscription_type}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                        </Table>
                                    </div>
                                    }
                                </Grid>
                                <Grid item xs={12} sm={7}>
                                    {this.state.paypal_mode=='charge' && 
                                        <div>
                                            <Grid container direction="row">
                                                <Grid item xs={12} sm={12}>
                                                    <Grid container direction="row">
                                                        <Grid item xs={12}>
                                                            <Typography variant="subtitle2">
                                                                Elige tu forma de pago
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                    <Grid container direction="row">
                                                        <Grid item sm={6} xs={12} style={{justifyContent: 'center'}}>
                                                            <Grid container direction="row">
                                                                <Grid item xs={12}>
                                                                    <Typography variant="subtitle2">
                                                                        Paypal
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12}>&nbsp;</Grid>
                                                                <Grid item xs={12}>
                                                                    <div id="paypal-button-container"></div>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item sm={6} xs={12} style={{justifyContent: 'center'}}>
                                                            <Grid container direction="row">
                                                                <Grid item xs={12}>
                                                                    <Typography variant="subtitle2">
                                                                        OpenPay
                                                                    </Typography>
                                                                </Grid>
                                                                
                                                                <Grid item xs={12}>
                                                                    <Grid container direction="row">
                                                                        
                                                                        <Grid item xs={12} sm={12}>
                                                                            <Grid container direction="row">
                                                                                <Grid item xs={12}>
                                                                                    <img style={{ width: '50%'}} src={openpay_credit_cards} alt="Tarjetas de crédito openpay" />
                                                                                </Grid>
                                                                            </Grid>
                                                                            <Grid container direction="row">
                                                                                <Grid item xs={12}>
                                                                                    <img style={{ width: '50%'}} src={openpay_debit_cards} alt="Tarjetas de débito openpay" />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        
                                                                        <Grid item xs={12} style={{justifyContent: 'center'}}>
                                                                            <Button variant="contained" 
                                                                                    onClick={()=>window.open(this.state.openpay_redirect_url)} 
                                                                                    style={{color: 'white', fontWeight: '900'}}
                                                                                    color="primary">
                                                                                    Realizar pago en línea
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                    
                                                                    <Grid container direction="row">
                                                                        <Grid item xs={12} sm={12} style={{paddingTop: '10px'}}>
                                                                            <Typography variant="subtitle2">
                                                                                O bien paga en tiendas:
                                                                            </Typography>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <a href={this.state.openpay_receipt_url} target="_blank">
                                                                                <img style={{ width: '80%'}} src={openpay_tiendas} alt="Tiendas openpay" />
                                                                            </a>
                                                                        </Grid>
                                                                        <Grid item xs={12} sm={12}>
                                                                            <Button variant="contained" 
                                                                                onClick={()=>window.open(this.state.openpay_receipt_url)} 
                                                                                style={{color: 'white', fontWeight: '900'}}
                                                                                color="primary">
                                                                                Descarga tu formato de pago
                                                                            </Button>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container direction="row">&nbsp;</Grid>
                                        </div>
                                    }
                                    
                                {this.state.paypal_mode=='confirm' && 
                                    <div>
                                        <Typography variant="subtitle1">
                                            ¡Hemos recibido tu pago por ${this.state.access_type.amount} exitosamente!
                                        </Typography>
                                    </div>
                                }
                                {this.state.openpay_mode=='confirm' && 
                                    <div>
                                        <Typography variant="subtitle1">
                                            ¡Hemos recibido tu pago por ${this.state.openpay_confirmation_payment.payment_amount} exitosamente!
                                        </Typography>
                                    </div>
                                }
                                {(this.state.openpay_mode=='confirm' || this.state.paypal_mode=='confirm') && 
                                    <Grid container direction="row">
                                        <Grid item xs={12} style={{justifyContent: 'center'}}>
                                            <Button variant="contained" 
                                                    onClick={()=>window.open(config.API_URL+'/events/'+config.EVENT_ID+'/badges/'+this.state.user_id)} 
                                                    style={{color: 'white', fontWeight: '900'}}
                                                    color="primary">
                                                    Descarga tu gafete aquí
                                            </Button>
                                        </Grid>
                                    </Grid>
                                }
                                </Grid>
                            </Grid>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{paddingBottom: '20px'}}>
                        <Grid container direction="row" style={{justifyContent: 'center'}}>
                            <Button variant="contained" 
                                    onClick={()=>this.handleOpenPayClose(this.state.paypal_mode=="confirm")} 
                                    style={{color: 'white', fontWeight: '700'}}
                                    color="primary">
                                    {this.state.paypal_mode=='charge' && 
                                    <span>Cancelar</span>}
                                    {(this.state.openpay_mode=='confirm' || this.state.paypal_mode=='confirm') && 
                                    <span>OK</span>}
                            </Button>
                        </Grid>
                    </DialogActions>
                </Dialog>

                <Dialog
                    open={this.state.confirmation_open}
                    TransitionComponent={Transition}
                    fullWidth={true}
                    maxWidth={'sm'}
                    keepMounted
                    onClose={()=>this.handleConfirmationClose()}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                    >
                    <DialogTitle id="alert-dialog-slide-title" style={{textAlign: 'center'}}>
                        <Typography variant='h3' style={{textTransform: 'none'}}>
                            ¡Confirmación exitosa!
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description" style={{textAlign: 'center'}}>
                            <Typography variant="subtitle1">
                                {this.state.name_to_confirm}
                            </Typography>
                            <Typography variant="subtitle2">
                                Hemos confirmado tu asistencia de forma correcta
                            </Typography>
                            <Typography variant="subtitle2">
                                &nbsp;
                            </Typography>
                            <Typography variant="subtitle2">
                                Recibirás un mail de confirmación para que descargues tu gafete de acceso. Si no has recibido tu gafete en menos de 24 hrs comunícate al siguiente correo: atencioncldc@gmail.com
                            </Typography>
                            <Typography variant="subtitle2">
                                &nbsp;
                            </Typography>
                            <Typography variant="subtitle2">
                                ¡Gracias!
                            </Typography>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions style={{paddingBottom: '20px'}}>
                        <Grid container direction="row" style={{justifyContent: 'center'}}>
                            <Button variant="contained" 
                                    onClick={()=>this.handleConfirmationClose()} 
                                    style={{color: 'white', fontWeight: '900'}}
                                    color="primary">
                                    Regresar al inicio
                            </Button>
                        </Grid>
                    </DialogActions>
                </Dialog>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    
                    open={this.state.error_open}
                    autoHideDuration={6000}
                    onClose={this.handleErrorClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<Typography style={{color: 'white'}}>{this.state.error_msg}</Typography>}
                    action={[
                        <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={this.handleErrorClose}
                        >
                        <CloseIcon />
                        </IconButton>,
                    ]}
                    />
            </div>
        );
    }   

    displayErrorSnackbar = (error_msg) => {
        this.setState({ error_msg: error_msg });
        this.setState({ error_open: true });
    };

    isValid(){
        this.setState({errorFields:[]});
        var valid = true;

        if(!this.isFieldValid('first_name')){
            valid = false;
        }

        if(!this.isFieldValid('last_name_1')){
            valid = false;
        }

        if(!this.isFieldValid('phone')){
            valid = false;
        }

        if(!this.isFieldValid('direccion')){
            valid = false;
        }

        if(!this.isFieldValid('email')){
            valid = false;
        }

        if(!this.isFieldValid('fecha_nacimiento')){
            valid = true;
        }

        if(!this.isFieldValid('genero')){
            valid = false;
        }
        
        if(!valid){
            this.displayErrorSnackbar("Por favor complete los campos requeridos");
            return false;
        }

        if(!validateEmail(this.state.email)){
            this.displayErrorSnackbar("Por favor verifique el formato de email");
            var errorFields = [];
            errorFields.push("email");
            this.setState({errorFields:errorFields});
            return false;
        }

        var today = new Date();
        var fecha_nac_date = new Date(this.state.fecha_nacimiento);
        if(fecha_nac_date.getTime()>today.getTime()){
            this.displayErrorSnackbar("Por favor revise la fecha de nacimiento, no puede ser futura");
            return false;
        }

        if(this.state.tiene_beca=="1"){
            if (!this.state.becario_codigo || this.state.becario_codigo===""){
                this.displayErrorSnackbar("Por favor proporciona tu código de becario");
                var errorFields = [];
                errorFields.push("becario_codigo");
                this.setState({errorFields:errorFields});
                return false;
            }else if(this.state.becario_codigo!=this.state.becario_codigo_correcto){
                this.displayErrorSnackbar("Código de becario incorrecto");
                var errorFields = [];
                errorFields.push("becario_codigo");
                this.setState({errorFields:errorFields});
                return false;
            }

        }

        return true;
    }

    isFieldValid(field_name){
        var valid = true;
        var errorFields = this.state.errorFields;
        if(!this.state[field_name] || this.state[field_name].trim()===""){
            errorFields.push(field_name);
            valid = false;
        }else{
            for(var i=0;i<errorFields.length;i++){
                if(errorFields[i]===field_name){
                    errorFields.splice(i,1);
                }
            }
        }

        this.setState({errorFields:errorFields});
        return valid;
    }

    isFieldWithError(field_name){
        return this.state.errorFields.includes(field_name);
    }
}

const screen_styles={
    logo:{
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center'
    },
    header:{
        height: '20vh',
        padding: '10px',
        color: 'white',
        backgroundColor: style_constants.header_bg_color,
        borderBottom: 'solid 5px '+style_constants.header_bg_color
    },
    event_title:{
        textAlign: 'center',
        padding: '30px'
    },
    main_container:{
        backgroundColor: style_constants.background_color,
        height: '100%'
    },
    section_title:{
        paddingLeft: '10vw',
        marginTop: '20px',
        marginBottom: '20px'
    },
    table:{
        width: '70vw'
    },
    selectedCell:{
        backgroundColor: style_constants.row_bg_color
    }
}

HomeScreen.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles, { withTheme: true })(HomeScreen);