import CookieStorage from './CookieStorage.js';
import formatDate from '../components/utils/formatDate.js';
import moment from 'moment';
import { type } from 'os';
import { config }  from './config.js';

export default class ServicesFacade
{

   constructor() {
   }

   retrieveGuestInvitationStatus(email, successCallback, errorCallback){
    
    let url = config.API_URL+'/verify_email?email='+email+'&event_id='+config.EVENT_ID;
    console.log("****FETCHING:: "+url);
    fetch(url, {
      method: 'GET',
      headers: {Accept: 'application/json',
                'Content-Type': 'application/json'}
    }).then((response) => {  
      const statusCode = response.status;
      console.log("Received status:: "+statusCode);
      return Promise.all([statusCode]);
    }).then(response => {
        let responseJson={status: response[0]};
        console.log("RECEIVED:: "+JSON.stringify(responseJson));
        if(responseJson.status>=200 && responseJson.status<300){
          successCallback(email);
        }else{
          errorCallback(responseJson.status, email);
        }
    })
    .catch((error) => {
      errorCallback(404,email);
    });

   }

   retrieveUserInEvent(email, successCallback, errorCallback){
    console.log("Consulting email::"+email);
    let url = config.API_URL+'/events/'+"albatros"+'/users/'+email;
    fetch(url, {
      method: 'GET',
      headers: {Accept: 'application/json',
                'Content-Type': 'application/json'}
    }).then((response) => {  
      const statusCode = response.status;
      return Promise.all([statusCode]);
    }).then(response => {
        let responseJson={status: response[0]};
        if(responseJson.status>=200 && responseJson.status<300){
          successCallback(email);
        }else{
          errorCallback(responseJson.status, email);
        }
    })
    .catch((error) => {
      errorCallback(404,email);
    });

   }

   retrieveUserTypes(event_id, successCallback, errorCallback){

    let url = config.API_URL+'/user_types/'+event_id+'/web';
    console.log("****FETCHING:: "+url);
    fetch(url, {
      method: 'GET',
      headers: {Accept: 'application/json',
                'Content-Type': 'application/json'}
    }).then((response) => {  
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    }).then(response => {
        let responseJson={status: response[0], data: response[1]};
        console.log("RECEIVED:: "+JSON.stringify(responseJson));
        if(responseJson.status>=200 && responseJson.status<300){
          successCallback(responseJson);
        }else{
          errorCallback(responseJson.status, responseJson.data);
        }
    })
    .catch((error) => {
      errorCallback(500,error);
    });

   }

   retrieveAccessTypes(event_id, successCallback, errorCallback){

    let url = config.API_URL+'/entrances/11';
    console.log("****FETCHING:: "+url);
    fetch(url, {
      method: 'GET',
      headers: {Accept: 'application/json',
                'Content-Type': 'application/json'}
    }).then((response) => {  
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    }).then(response => {
        let responseJson={status: response[0], data: response[1]};
        console.log("RECEIVED:: "+JSON.stringify(responseJson));
        if(responseJson.status>=200 && responseJson.status<300){
          successCallback(responseJson);
        }else{
          errorCallback(responseJson.status, responseJson.data);
        }
    })
    .catch((error) => {
      errorCallback(500,error);
    });

   }

   retrieveOpenPayRedirectLink(inscription_type_id, user_id, successCallback, errorCallback){

    let url = config.API_URL+'/payments/redirect_url/openpay/'+inscription_type_id+'/'+user_id;
    console.log("****FETCHING:: "+url);
    fetch(url, {
      method: 'GET',
      headers: {Accept: 'application/json',
                'Content-Type': 'application/json'}
    }).then((response) => {  
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    }).then(response => {
        let responseJson={status: response[0], data: response[1]};
        console.log("RECEIVED:: "+JSON.stringify(responseJson));
        if(responseJson.status>=200 && responseJson.status<300){
          successCallback(responseJson);
        }else{
          errorCallback(responseJson.status, responseJson.data);
        }
    })
    .catch((error) => {
      errorCallback(500,error);
    });

   }

   retrieveAttendance(user_id, event_id, language_code, successCallback, errorCallback){

    let url = config.API_URL+'/events/'+event_id+'/users/'+user_id+'/'+language_code;
    fetch(url, {
      method: 'GET',
      headers: {Accept: 'application/json',
                'Content-Type': 'application/json'}
    }).then((response) => {  
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    }).then(response => {
        let responseJson={status: response[0], data: response[1]};
        if(responseJson.status>=200 && responseJson.status<300){
          successCallback(responseJson);
        }else{
          errorCallback(responseJson.status, responseJson.data);
        }
    })
    .catch((error) => {
      errorCallback(500,error);
    });

   }

   postExtraFields(data, user_id, successCallback, errorCallback){

    fetch(config.API_URL+'/events/'+"27"+'/register_extra_fields/'+user_id, {
      method: 'POST',
      headers: {Accept: 'application/json',
                'Content-Type': 'application/json'},
      body: JSON.stringify(data),
    }).then((response) => {  
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    }).then(response => {
        let responseJson={status: response[0], data: response[1]};
        if(responseJson.status>=200 && responseJson.status<300){
          successCallback(responseJson);
        }else{
          errorCallback(responseJson.status, responseJson.data);
        }
    })
    .catch((error) => {
      console.error(JSON.stringify(error));
      errorCallback(500,error);
    });

   }

   putPaypalPaymentConfirmation(event_id, user_id, paypal_raw_response, paypal_transaction_id, paypal_amount, successCallback, errorCallback){

    var data={
      user_id: user_id,
      paypal_raw_response: paypal_raw_response,
      paypal_transaction_id: paypal_transaction_id,
      paypal_amount:paypal_amount
    }

     let url = config.API_URL+'/events/'+"27"+'/web/register/payment/';
     console.log("****FETCHING:: "+url);
      fetch(url, {
        method: 'PUT',
        headers: {Accept: 'application/json',
                  'Content-Type': 'application/json'},
        body: JSON.stringify(data)
      }).then((response) => {  
        const statusCode = response.status;
        return Promise.all([statusCode]);
      }).then(response => {
          let responseJson={status: response[0]};
          console.log("RECEIVED:: "+JSON.stringify(responseJson));
          if(responseJson.status>=200 && responseJson.status<300){
            successCallback(responseJson);
          }else{
            errorCallback(responseJson.status, {});
          }
      })
      .catch((error) => {
        errorCallback(500,error);
      });

   }
   putPaymentConfirmation(transaction_id,successCallback, errorCallback){

    let url = config.API_URL+'/payments/confirm/openpay/'+transaction_id;
    console.log("****FETCHING:: "+url);
    fetch(url, {
      method: 'PUT',
      headers: {Accept: 'application/json',
                'Content-Type': 'application/json'}
    }).then((response) => {  
      const statusCode = response.status;
      const data = response.json();
      return Promise.all([statusCode, data]);
    }).then(response => {
        let responseJson={status: response[0], data: response[1]};
        console.log("RECEIVED:: "+JSON.stringify(responseJson));
        if(responseJson.status>=200 && responseJson.status<300){
          successCallback(responseJson);
        }else{
          errorCallback(responseJson.status, responseJson.data);
        }
    })
    .catch((error) => {
      errorCallback(500,error);
    });

   }

   postRegistration(data, files, successCallback, errorCallback){
    let formData = new FormData();
    //Archivos
    if(files && files!=null && files.length>0){
      for(var i=0;i<files.length;i++){
        formData.append('archivo_'+(i+1), files[i], files[i].name);
      }
    }
    formData.append('titulo', data.titulo);
    formData.append('nombre', data.nombre);
    formData.append('apellido_paterno', data.apellido_paterno);
    formData.append('apellido_materno', data.apellido_materno);
    formData.append('genero', data.genero);
    formData.append('especialidad', data.especialidad);
    formData.append('sub_especialidad', data.sub_especialidad);
    formData.append('telefono_fijo', data.telefono_fijo);
    formData.append('celular', data.celular);
    formData.append('telefono_contacto', data.telefono_contacto);
    formData.append('correo_electronico', data.correo_electronico);
    formData.append('estado', data.estado);
    formData.append('direccion', data.direccion);
    formData.append('password', data.correo_electronico);
    formData.append('pais', data.pais);
    formData.append('tipo_inscripcion_id', data.tipo_inscripcion_id);
    formData.append('tipo_inscripcion', data.tipo_inscripcion);
    formData.append('tipo_usuario_id',data.tipo_usuario_id);
    formData.append('tipo_usuario', data.tipo_usuario);
    formData.append('paypal_amount', data.paypal_amount);
    formData.append('paypal_raw_response', data.paypal_raw_response);
    formData.append('paypal_transaction_id', data.paypal_transaction_id);

    fetch(config.API_URL+'/events/'+"27"+'/web/register/', {
        method: 'POST',
        body: formData,
      }).then((response) => {  
        const statusCode = response.status;
        const data = response.json();
        return Promise.all([statusCode, data]);
      }).then(response => {
          let responseJson={status: response[0], data: response[1]};
          if(responseJson.status>=200 && responseJson.status<300){
            successCallback(responseJson);
          }else{
            errorCallback(responseJson.status, responseJson.data);
          }
      })
      .catch((error) => {
        console.error(JSON.stringify(error));
        errorCallback(500,error);
      });
  }


}