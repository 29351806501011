export const user_data=[
    {id:1,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Arturo de la Rosa",company_name:"ABBVIE "},
    {id:2,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Arturo Rodríguez",company_name:"ACROM"},
    {id:3,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Luis Hernández",company_name:"ACROM "},
    {id:4,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Vincenzo D’Elia Nesta",company_name:"ALFASIGMA "},
    {id:5,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"José Luis Paz",company_name:"AMGEN"},
    {id:6,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Elvin Penn ",company_name:"AMGEN "},
    {id:7,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Alberto Langoni Cirese",company_name:"ARMSTRONG"},
    {id:8,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Raúl Camarena Flores",company_name:"ASPEN "},
    {id:10,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Alma Ortíz",company_name:"Astellas"},
    {id:11,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Livia Medina",company_name:"Astellas"},
    {id:9,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Luis González",company_name:"Astellas"},
    {id:12,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Sylvia Varela",company_name:"ASTRAZENECA"},
    {id:13,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Jorge Ancona",company_name:"BAYER "},
    {id:14,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Luis Calderón",company_name:"BIOGEN"},
    {id:15,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"David López",company_name:"BIOMARIN "},
    {id:16,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Miguel Alberto Salazar ",company_name:"BOEHRINGER INGELHEIM DE MÉXICO"},
    {id:17,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Sandra Ramirez ",company_name:"BRISTOL MYERS SQUIBB DE MÉXICO"},
    {id:18,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Edvard Philipson",company_name:"CELGENE "},
    {id:19,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Paul Hernández",company_name:"CELGENE "},
    {id:20,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Marco Ruggiero",company_name:"CHIESI"},
    {id:21,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Nicolás Martínez Gould",company_name:"CSL BEHRINGER"},
    {id:22,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Raúl Vivar",company_name:"EISAI "},
    {id:23,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:" Carlos Baños Urquijo",company_name:"ELI LILLY "},
    {id:24,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Almudena Suárez",company_name:"ELI LILLY "},
    {id:25,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Rodrigo Melgarejo",company_name:"ESPECÍFICOS STENDHAL"},
    {id:26,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Sandrine Duprez",company_name:"EXPANSCIENCE"},
    {id:27,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Irma Egoavil",company_name:"FERRING"},
    {id:28,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Erik Musalem",company_name:"GILEAD"},
    {id:29,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Rogelio Ambrosi",company_name:"GRÜNENTHAL "},
    {id:30,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:" Guillaume Lotigie",company_name:"IPSEN "},
    {id:31,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Enrique Alonso",company_name:"ISDIN "},
    {id:32,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Mario Sturion",company_name:"JANSSEN MÉXICO"},
    {id:33,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:" Juan Antonio Guerrero ",company_name:"LABORATORIOS SANFER"},
    {id:34,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Florent Darchez",company_name:"LABORATORIOS SERVIER "},
    {id:35,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:" Gerardo Cárdenas ",company_name:"LFB (INNOVARE)"},
    {id:37,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Claudia Guadalupe Gómez",company_name:"Lundbeck"},
    {id:36,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Kaya Pai Panandiker",company_name:"Lundbeck"},
    {id:38,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Maria del Pilar Pichardo",company_name:"Lundbeck"},
    {id:39,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:" Javier Amtmann",company_name:"MENARINI "},
    {id:40,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:" Christian Holmer",company_name:"MERCK SHARP & DOHME DE MÉXICO"},
    {id:41,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:" Patricia Faci",company_name:"MERCK SHARP & DOHME DE MÉXICO"},
    {id:42,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:" Ana Longoria",company_name:"NOVARTIS "},
    {id:43,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Yiannis Mallis",company_name:"NOVO NORDISK "},
    {id:46,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Lizete de la Torre",company_name:"PFIZER"},
    {id:45,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Ricardo Ramírez",company_name:"PFIZER"},
    {id:44,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Rodrigo Puga",company_name:"PFIZER"},
    {id:47,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Eduardo Sosa",company_name:"PIERRE FABRE "},
    {id:48,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:" Vicente Saro",company_name:"PRODUCTOS FARMACÉUTICOS CHINOIN"},
    {id:49,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Eric Hágsater ",company_name:"PRODUCTOS FARMACÉUTICOS CHINOIN"},
    {id:50,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Andrés Bruzual",company_name:"ROCHE"},
    {id:51,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Jorge Tanaka",company_name:"ROCHE"},
    {id:52,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Fernando Sampaio",company_name:"SANOFI "},
    {id:53,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Angélica Hurtado",company_name:"SERVIER"},
    {id:55,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"José Manuel Caamaño",company_name:"TAKEDA "},
    {id:56,inscription_type:"1|SOCIOS",invitation_status:"invited",first_name:"Daniel Amezcua Zavala",company_name:"UCB DE MÉXICO"},
    {id:57,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Edgar García",company_name:"Avanti"},
    {id:58,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Víctor de Lucio",company_name:"Avanti"},
    {id:60,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Iñigo Cantú",company_name:"Care Abogados"},
    {id:59,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Tere Cantú",company_name:"Care Abogados"},
    {id:61,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Dr. Juan Francisco Millán",company_name:"Cetifarma"},
    {id:62,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Marco Escotto",company_name:"Cetifarma"},
    {id:63,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Juan Carlos Castillo",company_name:"CG&A, Legal y Asuntos Públicos"},
    {id:64,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Luis Foncerrada",company_name:"Consultor"},
    {id:67,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Edgar Fernando Margain",company_name:"Despacho Rojas del Castillo"},
    {id:66,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Elizabeth Vara",company_name:"Despacho Rojas del Castillo"},
    {id:65,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Jessica Abonza",company_name:"Despacho Rojas del Castillo"},
    {id:68,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Joge Espinoza",company_name:"GAE"},
    {id:69,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Lisandro Herrera",company_name:"Galicia"},
    {id:71,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Aaron Soto",company_name:"KPMG"},
    {id:70,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Libertad Tellez",company_name:"KPMG"},
    {id:72,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Alejandro Luna",company_name:"Olivares y Cia."},
    {id:73,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Juan Carlos Sousa",company_name:"Prospectiva"},
    {id:74,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Solange Márquez",company_name:"Prospectiva"},
    {id:75,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Gonzalo Olivares",company_name:"Psiquepol"},
    {id:76,inscription_type:"2|CONSULTORES",invitation_status:"invited",first_name:"Joselyne Rivera",company_name:"Psiquepol"},
    {id:77,inscription_type:"3|EQUIPO AMIIF",invitation_status:"invited",first_name:"Cristóbal Thompson",company_name:"AMIIF"},
    {id:78,inscription_type:"3|EQUIPO AMIIF",invitation_status:"invited",first_name:"Cynthia Ramírez",company_name:"AMIIF"},
    {id:86,inscription_type:"3|EQUIPO AMIIF",invitation_status:"invited",first_name:"Darián Garteiz",company_name:"AMIIF"},
    {id:79,inscription_type:"3|EQUIPO AMIIF",invitation_status:"invited",first_name:"Fernando Fon",company_name:"AMIIF"},
    {id:85,inscription_type:"3|EQUIPO AMIIF",invitation_status:"invited",first_name:"Fernando Portugal",company_name:"AMIIF"},
    {id:80,inscription_type:"3|EQUIPO AMIIF",invitation_status:"invited",first_name:"Gabriel Martínez",company_name:"AMIIF"},
    {id:83,inscription_type:"3|EQUIPO AMIIF",invitation_status:"invited",first_name:"Jonathan Villordo",company_name:"AMIIF"},
    {id:81,inscription_type:"3|EQUIPO AMIIF",invitation_status:"invited",first_name:"Karla Báez",company_name:"AMIIF"},
    {id:82,inscription_type:"3|EQUIPO AMIIF",invitation_status:"invited",first_name:"Paola Villers",company_name:"AMIIF"},
    {id:84,inscription_type:"3|EQUIPO AMIIF",invitation_status:"invited",first_name:"Verónica Mendoza",company_name:"AMIIF"},
    {id:87,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Carlos Jiménez",company_name:"AMID"},
    {id:88,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Fernando Oliveros",company_name:"AMID"},
    {id:89,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Dr. Dagoberto Cortés Cervantes",company_name:"Anafam"},
    {id:90,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Carlos Castro",company_name:"Asociación ALE"},
    {id:91,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Lucila Castro",company_name:"Asociación ALE"},
    {id:92,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Guillermo Funes",company_name:"Canifarma"},
    {id:93,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Rafael Gual",company_name:"Canifarma"},
    {id:94,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Javier Potes",company_name:"CCE"},
    {id:95,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Patrick Devlyn",company_name:"CCE"},
    {id:96,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Raul Riquelme",company_name:"CCE"},
    {id:97,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Recaredo Arias",company_name:"CCE"},
    {id:98,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Cecilia Sayeg",company_name:"CEEG"},
    {id:99,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Frederic Garcia",company_name:"CEEG"},
    {id:100,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Dr. Juan Carlos Gallaga",company_name:"COFEPRIS"},
    {id:101,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Lic. Jorge Romero",company_name:"COFEPRIS"},
    {id:102,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Lic. Lázaro E. Ávila",company_name:"COFEPRIS"},
    {id:103,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Lic. Dayra Vergara Vargas ",company_name:"Coordinadora Nacional del Programa Jóvenes Construyendo el Futuro"},
    {id:104,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Lic. Francisco Pérez Fayad",company_name:"Fármacos Especializados"},
    {id:105,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Hector Valle",company_name:"Funsalud"},
    {id:106,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Ricardo Ramírez",company_name:"Hudderynd"},
    {id:107,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Gabriela Alarcón",company_name:"IMCO"},
    {id:108,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Manuel Molano",company_name:"IMCO"},
    {id:109,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Ángeles Martínez",company_name:"IMS Health"},
    {id:110,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Xavier Valdés",company_name:"IMS Health"},
    {id:111,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Ana Lucía Rosado ",company_name:"INST. INT. DE FUTUROS DE LA SALUD"},
    {id:112,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Dr. Enrique Ruelas",company_name:"INST. INT. DE FUTUROS DE LA SALUD"},
    {id:113,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Enrique Ruelas",company_name:"Instituto de Futuros"},
    {id:114,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Carolina Morfín",company_name:"LSC"},
    {id:115,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Javier Picó",company_name:"LSC"},
    {id:116,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"José Carlos Rodríguez P.",company_name:"Manatt Jones"},
    {id:117,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Lic. José Alberto Peña",company_name:"Marzam"},
    {id:118,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Miguel Ángel Galindo",company_name:"Marzam"},
    {id:119,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Pablo Escandón",company_name:"Nadro"},
    {id:120,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Dra. Jennie Ward- Robinson",company_name:"PAHO Foundation"},
    {id:121,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Kenneth Thorpe",company_name:"PFCD"},
    {id:122,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Kevin Walker",company_name:"PFCD"},
    {id:123,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Ana Cabrera",company_name:"PwC"},
    {id:124,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Gerardo Sam",company_name:"PwC"},
    {id:125,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Jose Alarcon",company_name:"PwC"},
    {id:126,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Karinna Ceballos",company_name:"PwC"},
    {id:127,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Lorena de la Garza",company_name:"PwC"},
    {id:128,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Ruth Tejero",company_name:"PwC"},
    {id:129,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Lic. Alejandro Sanders Villa",company_name:"Secretaría de Salud"},
    {id:130,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Lic. Enrique Balp Díaz",company_name:"Secretaría de Salud"},
    {id:131,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Mtro. Jacobo Cadena Lau",company_name:"Secretaría de Salud"},
    {id:132,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Mtra. Luisa María Alcalde Luján  ",company_name:"Secretaria del Trabajo y Previsión Social del Gobierno de México."},
    {id:133,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Dr. José Narro Robles",company_name:"Secretario de Salud Federal"},
    {id:134,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"José Aedo",company_name:"Singrem"},
    {id:135,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Germán Fajardo",company_name:"UNAM"},
    {id:136,inscription_type:"4|INVITADOS",invitation_status:"invited",first_name:"Inés Nandín",company_name:"White Ink House"}
];